import React from 'react'
import Image from 'gatsby-image'
import { navigate } from 'gatsby'

import Content from 'components/Content'
import 'styles/Hero.scss'

const HeroVideo = ({
  fluidImage,
  title,
  subtitle,
  description,
  video,
  className = '',
}) => {
  return (
    <section
      className={`hero video-hero heroComponent position-relative ${className} no-column`}
    >
      <Image
        loading="eager"
        fadeIn={false}
        fluid={fluidImage}
        className="hero-image hero-video-image"
      />
      <div className="container hero-column d-flex flex-wrap justify-content-center">
        <div className="hero-video-titles pt-xl-5 container row">
          <div className="col-lg-5">
            <div className="pt-3">
              <h1 className="with-dot-nested mb-lg-4 mb-3">
                {title}
                <b></b>
              </h1>
              <h2>{subtitle}</h2>
              <div
                onClick={() => navigate('#research-download')}
                className="button mt-3"
              >
                Download onderzoeksrapport
              </div>
            </div>
          </div>
          <div className="col-lg-7 pt-lg-0 pt-4">
            <div className="hero-video-titles-videocontainer">
              <iframe
                autoPlay={1}
                loop={1}
                width="100%"
                height="100%"
                src={video}
                title="Veritec"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
              />
            </div>
          </div>
        </div>
        <div className="hero-row row justify-content-center align-items-end">
          <div className="col-12 hero-video-card col-md-10 card d-block">
            {description && <Description description={description} />}
          </div>
        </div>
      </div>
    </section>
  )
}

const Description = ({ description }) => {
  return typeof description === 'object' ? (
    <div className="mb-lg-4">{description}</div>
  ) : (
    <Content className="mb-lg-4" content={description} />
  )
}

export default HeroVideo
