import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from "gatsby";
import { v4 } from 'uuid';
import styled from 'styled-components'

import Content from "components/Content";
import "styles/GravityForm.scss";
import { chunkArray, calculateColumn } from "../utils";

const DownloadForm = ({ id, perColumn, downloadLink }) => {
  const { allGfForm: { edges: gravityData } } = useStaticQuery(graphql`
    query {
      allGfForm {
        edges {
          node {
            formId
            slug
            apiURL
            descriptionPlacement
            formFields {
              checkboxLabel
              id
              label
              labelPlacement
              description
              descriptionPlacement
              type
              choices
              content
              errorMessage
              inputMaskValue
              isRequired
              visibility
              cssClass
              placeholder
              size
              defaultValue
              maxLength
            }
            button {
              text
            }
            confirmations {
              message
            }
          }
        }
      }
    }
  `)

  const { node: gfForm } = gravityData.filter(({ node }) => node.formId === 4)[0];

  const [fields, setFields] = useState({});
  const [form, setForm] = useState(gfForm);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    let tempForm = form;

    // add submit button as a field
    if (tempForm.formFields.filter((item) => item.type === "submit").length === 0)  {
      tempForm.formFields = [
        ...tempForm.formFields,
        {
          formId: v4(),
          type: "submit",
          text: tempForm.button.text
        }
      ];
    }

    if (perColumn) {
      tempForm = {
        ...tempForm,
        formFields: chunkArray(tempForm.formFields, perColumn)
      };
    }

    setForm({
      ...form,
      ...tempForm
    });
  }, [])

  async function handleOnSubmit(event) {
    event.preventDefault();

    if (status === 'processing') {
      return;
    }

    setStatus('processing');

    try {
      const formData = new FormData();

      for (const [key, value] of Object.entries(fields)) {
        formData.append(key, value);
      }

      const request = await fetch(`${form.apiURL}/submissions`, {
        method: 'POST',
        body: formData
      });

      const response = await request.json();
      if (response.is_valid === true) {
        setStatus('done');

        setMessage(response.confirmation_message);
      } else {
        setStatus('error');
        console.log(response);
      }
    } catch (error) {
      setStatus('error');
      console.error(error);
    }
  }

  function handleFieldChange(event) {
    // eslint-disable-next-line prefer-destructuring
    let value = event.target.value;

    if (event.target.type === "checkbox") {
      value = event.target.checked ? event.target.value : "";
    }

    setFields({
      ...fields,
      [event.target.name]: value
    });
  }

  if (status === "done") {
    return <div className="form-submission-message">
      Bedankt voor het invullen van het formulier.
      <a target="_blank" rel='norefferer noopener' href={downloadLink} download className='button mt-4'>
        Hier is uw downloadlink
      </a>
    </div>
  }

  if (form.formFields) {
    return (
      <form id={`form_${form.formId}`} method="post" className={`form form_${form.formId}`} onSubmit={handleOnSubmit}>
        {status === "processing" && (
          <Loading />
        )}
        <div className="row">
          {form.formFields && form.formFields.map((field, key) => {
            if (Array.isArray(field)) {
              return (
                <div key={key} className={`col-lg-${calculateColumn(form.formFields.length)}`}>
                  {field.map((item, index) => (
                    <FormField key={index} field={item} fields={fields} onChange={handleFieldChange} />
                  ))}
                </div>
              )
            }

            return (
              <div key={key} className="col-lg-12">
                <FormField field={field} fields={fields} onChange={handleFieldChange} />
              </div>
            )
          })}
        </div>
      </form>
    )
  }

  console.error("No gravity forms found with id", id);
  return false;
}

const TextField = ({ value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <div className="form-group">
    {labelPlacement !== "hidden_label" && <label htmlFor={`input_${id}`}>{label}</label>}
    <input
      value={value}
      onChange={onChange}
      type="text"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const EmailField = ({ value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <div className="form-group">
    {labelPlacement !== "hidden_label" && <label htmlFor={`input_${id}`}>{label}</label>}
    <input
      value={value}
      onChange={onChange}
      type="email"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const PhoneField = ({ value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <div className="form-group">
    {labelPlacement !== "hidden_label" && <label htmlFor={`input_${id}`}>{label}</label>}
    <input
      value={value}
      onChange={onChange}
      type="tel"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const TextAreaField = ({ value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <div className="form-group">
    {labelPlacement !== "hidden_label" && <label htmlFor={`input_${id}`}>{label}</label>}
    <textarea
      value={value}
      onChange={onChange}
      type="tel"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </div>
)

const CheckboxField = ({ value, onChange, field: { id, type, cssClass, choices } }) => {
  const list = JSON.parse(choices);

  return (
    <div className="form-group">
      {list.map((checkbox, key) => (
        <div key={key} className="form-group__checkboxes">
          <input
            checked={value}
            onChange={onChange}
            type="checkbox"
            id={`input_${id}_${key + 1}`}
            className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
            name={`input_${id}_${key + 1}`}
            value={checkbox.value}
          />
          <label htmlFor={`input_${id}_${key + 1}`} className="checkbox-content">{checkbox.text}</label>
        </div>
      ))}
    </div>
  )
}

const StyledCustomLink = styled.a`
  text-decoration: underline;
`

const ConsentLabel = styled.label`

  ${props => props.formType === 'contact' && css`
    color: ${props.theme.color.text.contrast};
  `}

  ${props => props.formType === 'event' && css`
    color: ${props.theme.color.text.light};
  `}

  ${props => props.formType === 'newsletter' && css`
    color: ${props.theme.color.text.light};
  `}
`

const ConsentField = ({onChange, value, field}) => {
  const list = JSON.parse(field.choices)

  return (
    <div className="form-group">
      <div key={field.id} className="form-group__checkboxes d-flex align-items-start">
        <input
          checked={value}
          onChange={onChange}
          type="checkbox"
          id={`input_${field.id}`}
          className={`mt-1 mr-2 ${field.type} ${field.cssClass !== undefined ? field.cssClass : ""}`}
          name={`input_${field.id}`}
          value={list[0].value}
        />
        <ConsentLabel htmlFor={`input_${field.id}`} className="checkbox-content">
          {`Ja, ik ga akkoord met de `} 
          <StyledCustomLink target="_blank" href="https://admin.veritecyachts.nl/wp-content/uploads/2021/09/Algemene-Voorwaarden-TBA-en-WS-2020.pdf">algemene voorwaarden</StyledCustomLink>
          {` van Veritec`}
        </ConsentLabel>
      </div>
    </div>
  )
}

const SubmitButton = ({ field }) => (
  <div className="form-footer d-flex justify-content-center">
    <button className="button form-submit ml-0" type="submit">
      {field.text}
    </button>
  </div>
)

export const FormField = ({ field, fields, onChange }) => (
  <>
    {field.type === "text" && <TextField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "email" && <EmailField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "phone" && <PhoneField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "textarea" && <TextAreaField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "checkbox" && <CheckboxField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "consent" && <ConsentField onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "submit" && <SubmitButton field={field} />}
  </>
)

export const Loading = () => (
  <div className="form-loading">
    <div className="form-loading-spinner">
      <div className="sk-chase">
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
        <div className="sk-chase-dot" />
      </div>
    </div>
  </div>
);

export default DownloadForm;


