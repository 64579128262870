import React from 'react'
import { graphql, Link } from 'gatsby'
import Image from 'gatsby-image'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import Content from 'components/Content'
import HeroVideo from 'components/HeroVideo'
import DownloadForm from 'components/DownloadForm'
import GravityForm from 'components/GravityForm'

import 'styles/Research.scss'

const HeroContent = (hero) => (
  <>
    <Content className="mb-4" content={hero.description} />
  </>
)

const Research = ({ data }) => {
  const { wordpressPage: page } = data
  const {
    acf: { hero, download, challenge },
  } = page

  return (
    <Layout>
      <SEO
        yoast={{ meta: page.yoast_meta }}
        lang={page.wpml_current_locale.slice(0, 2)}
      />
      <div className="about-page">
        <HeroVideo
          className="about-hero"
          fluidImage={hero.image.localFile.childImageSharp.fluid}
          title={hero.title}
          subtitle={hero.subtitle}
          video={hero.video}
          description={HeroContent(hero)}
        />

        <div className="container download-container py-lg-5 py-3">
          <div className="row" id="research-download">
            <div className="col-lg-6 download-title with-dot-nested position-relative">
              <h2>{download.title}</h2>
              <b></b>
              <Image
                className="download-image"
                fluid={download.image.localFile.childImageSharp.fluid}
                alt=""
              />
            </div>
            <div className="col-lg-6 d-flex justify-content-end">
              <div className="download-form">
                <DownloadForm
                  downloadLink={download.research.localFile.url}
                  id={download.formid}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container py-lg-5 py-3">
          <div className="row justify-content-center">
            <div className="col-lg-11">
              <div className="challenge-box">
                <Content content={challenge.description} />
                <div className="d-flex w-100 research-form-wrapper justify-content-center pt-4">
                  <GravityForm id={5} perColumn={4} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-md-block d-none" style={{ height: 80 }} />
      </div>
    </Layout>
  )
}

export default Research

export const query = graphql`
  query ResearchPageByID($wordpress_id: Int!) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      id
      wordpress_id
      title
      slug
      content
      wpml_current_locale
      yoast_meta {
        name
        content
        property
      }
      acf {
        hero {
          title
          subtitle
          image {
            localFile {
              childImageSharp {
                fluid(quality: 99, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          video
          description
        }

        download {
          title
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 1000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
          formid
          research {
            localFile {
              url
            }
          }
        }

        challenge {
          description
          link {
            title
            url
          }
        }
      }
    }
  }
`
